import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import SimpleContactUs from "components/forms/SimpleContactUs";
import ContactUs from "pages/ContactUs";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <ContactUsForm description="Let us know if you have any questions or problems" />
    <Footer />
  </AnimationRevealPage>
);
